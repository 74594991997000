interface Year {
    year: number;
    months: Month[];
}

interface Month {
    month: number;
    days: Day[];
};

interface Day {
    day: number;
    holiday: boolean;
    data: string[];
    moon: Activity[];
    planets: Activity[];
    lunar_day: LunarDay[];
    moon_phase: MoonPhase;
}

interface Activity {
    activity: string;
    time: string;
    highlight: boolean;
}

interface LunarDay {
    day: number;
    time: string;
}

interface MoonPhase {
    phase: Phase;
    time: string;
}

enum Phase {
    first_q = 'first_q',
    full_moon = 'full_moon',
    last_q = 'last_q',
    new_moon = 'new_moon'
}

export type { Year, Month, Day, Activity, LunarDay, MoonPhase };
export { Phase };